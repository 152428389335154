.container {
  max-width: 150rem;
  margin: auto;

  padding-left: 2.4rem;
  padding-right: 2.4rem;
}

.text-uppercase {
  text-transform: uppercase;
}

.font-bold {
  font-weight: 600;
}

.font-italic {
  font-style: italic;
}

.mb-small {
  margin-bottom: 1.6rem;
}

.mt-small {
  margin-top: 1.6rem;
}

.mt-medium {
  margin-top: 3.6rem;
}

.link {
  color: $color-info !important;
  cursor: pointer;

  // &:link,
  // &:visited {
  //   color: $color-info;
  // }
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.align-center {
  text-align: center !important;
}

.mb-medium {
  margin-bottom: 2.4rem !important;
}

.flex-bottom {
  display: flex;
  align-items: flex-end;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999999;
  cursor: pointer;
}

.logout-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;

  align-items: center;
  justify-content: center;
  gap: 1.8rem;

  font-size: 3.6rem;
  font-weight: 500;
}

.hidden {
  display: none;
}

.grid-full {
  grid-column: 1 / -1;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom-menu-clearfix {
  height: 30rem;
}

.hidden {
  display: none !important;
}

.simple-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.8rem;

  & span {
    cursor: pointer;

    display: flex;
    align-items: center;
    gap: 0.8rem;
  }
}

.text-info {
  color: $color-info !important;
}

.text-danger {
  color: $color-danger !important;
}

.tracking {
  @media (max-width: 900px) {
    padding: 4.8rem 0 !important;
  }
}
