.button {
  // background-color: $color-info;
  background-color: $bg-primary;
  color: $color-white;
  border-radius: 0.6rem;
  height: 4.8rem;
  font-family: inherit;
  border: none;
  cursor: pointer;

  &--padding {
    padding: 0 3.6rem;
  }

  /*For link support*/
  @include btn-link-support;

  flex-shrink: 0;

  &--danger {
    background-color: $color-danger;
  }

  &--success {
    background-color: $color-success;
  }

  &:disabled {
    opacity: 0.6;
    cursor: initial;
  }

  & ion-icon {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
  }
}

.button1 {
  border: 1px solid $color-info;
  background-color: transparent;
  color: $color-info;
  border-radius: 0.6rem;
  height: 4.8rem;
  font-family: inherit;
  cursor: pointer;

  /*For link support*/
  @include btn-link-support;

  &:hover {
    background-color: $color-info;
    color: $color-white;
  }
}

.button-group-2 {
  display: flex;
  gap: 1.2rem;

  & > * {
    flex: 1;
  }
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;

  height: 4.8rem;
  padding: 0 1.6rem;
  border-radius: 0.8rem;

  &--primary {
    background-color: $bg-primary;
    color: $color-white;
  }

  &--success {
    background-color: $color-success;
    color: $color-white;
  }

  &--info {
    background-color: $color-info;
    color: $color-white;
  }

  &--outline {
    background-color: transparent;
    border: 1px solid #d9d9d9;
    transition: all 0.2s;

    &:hover {
      background-color: #f1f1f1;
    }
  }

  &--icon {
    padding: 0;
    width: 4.8rem;
    height: 4.8rem;
    justify-content: center;
  }

  & ion-icon {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
  }
}
