@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap");

html {
  font-size: 62.5%;

  @media (max-width: 750px) {
    font-size: 55%;
  }

  @media (max-width: 550px) {
    font-size: 50%;
  }
}

body {
  font-family: "Karla", sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.5;
  color: $text-color;
}
