.new-shipping {
  display: flex;
  gap: 3.6rem;
  align-items: flex-start;

  &__aside {
    flex: 0 0 30rem;

    display: flex;
    flex-direction: column;
    gap: 1.4rem;

    position: sticky;
    top: 10.4rem;
  }

  &__main {
    flex: 1;

    display: flex;
    flex-direction: column;
    gap: 4.8rem;

    min-width: 0;
  }

  &__form-section {
    display: flex;
    flex-direction: column;
    gap: 3.6rem;
  }

  &__form-header {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    justify-content: space-between;

    border-bottom: 1px solid #d7d7d7;
    padding: 1.8rem 1rem;
    font-size: 1.8rem;
    font-weight: 500;
    color: #000;

    // & span {
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;

    //   width: 4.8rem;
    //   height: 4.8rem;
    //   border-radius: 50%;
    //   background-color: #eaeaea;
    // }
  }

  &__info {
    position: relative;

    & ion-icon {
      display: block;
      width: 2.4rem;
      height: 2.4rem;
      color: #f08c00;
    }
  }

  &__info-main {
    display: flex;
    align-items: center;
    gap: 8px;

    cursor: pointer;

    & img {
      display: block;
      width: 3.6rem;
      height: 3.6rem;
      border-radius: 50%;
    }
  }

  &__info-block {
    position: absolute;
    top: 0;
    left: 0;
    width: 35rem;
    max-width: calc(100vh - 4.8rem);
    transform: translateX(-100%);
    z-index: 99999;

    display: none;

    &:hover {
      display: flex;
    }
  }

  &__info-main:hover + &__info-block {
    display: flex;
  }

  &__info-block-top {
    flex: 0 0 2.4rem;
  }

  &__info-block-main {
    flex: 1;

    background-color: #fff;
    border-radius: 2.4rem;

    box-shadow: 0 8px 64px rgba($color: #000000, $alpha: 0.1);

    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    & div {
      display: flex;
      flex-direction: column;
    }

    & h3 {
      font-size: 1.6rem;
      font-weight: 700;
      color: #000;
      text-align: center;
      padding: 1.8rem;
      border-bottom: 1px solid #eaeaea;
    }

    & ul {
      list-style: none;

      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.2rem;

      padding: 2.4rem;

      & li {
        display: flex;
        align-items: center;
        gap: 0.8rem;

        font-size: 1.4rem;
        color: #555;
        text-align: center;

        & ion-icon {
          display: block;
          width: 1.8rem;
          height: 1.8rem;
          flex-shrink: 0;
          color: #f08c00;
        }
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }

  &__form-grid-1 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.4rem;
  }

  &__form-grid-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.4rem;
  }

  &__checks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.2rem;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1.6rem;

    & > * {
      flex: 0 0 20rem;
    }
  }
}

.step {
  display: flex;
  align-items: center;
  gap: 1.2rem;

  border: 1px solid #eaeaea;
  border-radius: 1.2rem;
  cursor: pointer;
  padding: 1.8rem;

  transition: all 0.3s;

  &:hover,
  &--active {
    background-color: #f1f1f1;
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 3.6rem;
    height: 3.6rem;

    border-radius: 50%;
    font-family: inherit;
    border: none;
    background-color: #d7d7d7;

    & ion-icon {
      display: none;
      width: 1.8rem;
      height: 1.6rem;
    }
  }

  &--done span {
    display: none;
  }

  &--done &__btn {
    & ion-icon {
      display: block;
    }
  }
}
