.success-modal {
  background-color: #fff;
  width: 40rem;
  max-width: 95%;
  max-height: 80vh;
  border-radius: 4px;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999999;

  padding: 4.8rem 2.4rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  & h3 {
    font-size: 2.4rem;
    font-weight: 600;
    color: #000;
  }

  &__btns {
    align-self: stretch;

    margin-top: 2.4rem;

    display: flex;
    align-items: center;
    gap: 1.6rem;

    & button {
      flex: 1;
      height: 4.8rem;
    }
  }
}

.modal {
  position: fixed;
  top: -50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s;
  z-index: 99999999;

  width: 80rem;
  max-width: 95%;
  max-height: 80vh;
  background-color: #f7f7f7;
  border-radius: 4px;

  display: flex;
  flex-direction: column;

  &--xs {
    width: 45rem;
  }

  &--sm {
    width: 55rem;
  }

  &--open {
    top: 50%;
  }

  &__header {
    flex-shrink: 0;

    padding: 1.8rem 2.4rem;
    border-bottom: 1px solid #e6e6e6;
    text-transform: uppercase;
    color: #000;
    font-weight: 500;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.6rem;
  }

  &__heading {
    text-transform: uppercase;
    font-weight: 600;

    margin-right: auto;
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 1.2rem;
  }

  &__action {
    flex-shrink: 0;
    cursor: pointer;

    & ion-icon {
      display: block;
      width: 2.4rem;
      height: 2.4rem;
      pointer-events: none;
    }
  }

  &__body {
    flex: 1;

    padding: 2.4rem;
    min-width: 0;
    overflow: hidden;

    display: flex;
    flex-direction: column;

    overflow-y: scroll;
  }

  // &__filters {
  //   display: flex;
  //   align-items: center;
  //   justify-content: flex-end;
  //   gap: 1.6rem;

  //   margin-bottom: 2.4rem;
  // }

  // &__filter {
  //   flex: 0 0 20rem;
  // }

  &__footer {
    flex-shrink: 0;

    padding: 1.8rem 2.4rem;
    border-top: 1px solid #e6e6e6;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.6rem;
  }
}

.form-flex {
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
}
