.table-responsive {
  width: 100%;

  padding: 0.8rem;
  border-radius: 1.2rem;
  background-color: #fff;

  &--scroll {
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &--1 {
    padding: 0;
  }
}

.table {
  width: 100%;
  border-collapse: collapse;

  &__actions {
    display: flex;
    align-items: center;
    gap: 1.2rem;
  }

  &__action {
    cursor: pointer;

    & ion-icon {
      pointer-events: none;
      display: block;
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  & thead {
  }

  & thead tr {
    background-color: #f1f1f1;
  }

  &--bordered thead tr {
    border-left: 1px solid #f1f1f1;
    border-right: 1px solid #f1f1f1;
  }

  &--primary thead tr {
    background-color: $bg-primary;
    color: #fff;
    border-left: 1px solid $bg-primary;
    border-right: 1px solid $bg-primary;
  }

  &--2 thead tr {
    border-top: 1px solid #f1f1f1;
    background-color: transparent;
  }

  & tr {
    // background-color: var(--header-bg);
    cursor: pointer;
    // white-space: nowrap;

    &:not(:last-child) {
      border-bottom: 1px solid #f7f7f7;
    }
  }

  &--bordered tr {
    border-bottom: none !important;
  }

  &--bordered tbody {
    border-left: 1px solid #eaeaea;
    border-right: 1px solid #eaeaea;
  }

  &--2 tbody tr:nth-child(odd) {
    background-color: #f7f7f7;
  }

  & tbody tr:hover {
    // background-color: var(--table-hover);
  }

  & th {
    text-align: left;
    // text-transform: uppercase;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    padding-right: 2.4rem;

    &:first-child {
      padding-left: 1.6rem;
    }

    &:nth-child(1) {
      border-radius: 0.8rem 0px 0px 0.8rem;
    }
    &:nth-last-child(1) {
      border-radius: 0px 0.8rem 0.8rem 0px;
    }
  }

  &--scroll th {
    white-space: nowrap;
  }

  &--bordered th {
    padding: 1.2rem;

    &:not(:last-child) {
      border-right: 1px solid #eaeaea;
    }

    &:nth-child(1) {
      border-radius: 0;
    }
    &:nth-last-child(1) {
      border-radius: 0;
    }
  }

  &--2 th {
    border-right: none !important;
  }

  & td {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    color: var(--text-color);
    // white-space: nowrap;
    padding-right: 2.4rem;

    &:first-child {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
    }

    &:last-child {
      padding-right: 1.2rem;
    }
  }

  &--scroll td {
    white-space: nowrap;
  }

  &--bordered td {
    padding: 1.2rem;

    border-right: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;

    &:last-child {
      border-right: none;
    }
  }

  &--2 td {
    border-right: none;
  }
}
