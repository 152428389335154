.shipments-import {
  max-width: 70rem;
  margin: auto;

  background-color: #fff;
  padding: 4.8rem;
  border-radius: 1.2rem;

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;

    margin-bottom: 3.2rem;
  }

  &__heading {
    font-size: 2.4rem;
  }

  &__download {
    display: flex;
    align-items: center;
    gap: 6px;
    color: $color-info;

    position: relative;
    padding-bottom: 4px;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $color-info;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }

  &__footer {
    margin-top: 4.8rem;
  }
}

.shipments-import-info {
  display: flex;
  gap: 3.6rem;

  &__header {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 6px;

    margin-bottom: 3.2rem;

    & h3 {
      font-size: 2.4rem;
    }
  }

  &__metadata {
    display: flex;
    align-items: center;
    gap: 1.8rem;

    & p {
      display: flex;
      align-items: center;
      gap: 1.2rem;
    }
  }

  &__aside {
    flex: 0 0 40rem;
  }

  &__main {
    flex: 1;
  }
}
