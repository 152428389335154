.pac-container {
  background-color: #fff;
  font-family: "Karla", sans-serif !important;
  font-size: 1.4rem;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.07);
  //   border-radius: 1.2rem;
  //   overflow: hidden;

  height: auto;
  max-height: 30rem;
  overflow-y: scroll;

  margin-top: 1.2rem;
  color: $text-color;

  z-index: 9999999999;

  &::-webkit-scrollbar {
    width: 5px;

    // width: 16px;
  }

  &::-webkit-scrollbar-track {
    background: #f7f7f7;
  }

  &::-webkit-scrollbar-thumb {
    background: #d9d9d9;

    // border: 4px solid transparent;
    // background-clip: padding-box;
    // border-radius: 10rem;
  }

  &--custom {
    position: absolute;
    bottom: -1.2rem;
    left: 0;
    width: 100%;
    transform: translateY(100%);
    z-index: 99999;
    box-shadow: 0 8px 64px rgba(0, 0, 0, 0.1);
  }
}

.pac-item-1 {
  display: flex;
  align-items: center;
  gap: 1.2rem;

  padding: 1.2rem 1.8rem;
  cursor: pointer;

  transition: all 0.3s;

  //   white-space: nowrap;
  //   overflow: hidden;
  //   text-overflow: ellipsis;

  &:not(:last-child) {
    border-bottom: 1px solid #eaeaea;
  }

  &:hover {
    background-color: #fafafa;

    // white-space: initial;
  }
}

.pac-item {
  display: flex;
  align-items: center;
  gap: 1.2rem;

  padding: 1.2rem 1.8rem;
  cursor: pointer;
  border-top: none;

  transition: all 0.3s;

  &--center {
    justify-content: center;
  }

  //   white-space: nowrap;
  //   overflow: hidden;
  //   text-overflow: ellipsis;

  &:not(:last-child) {
    border-bottom: 1px solid #eaeaea;
  }

  &:hover {
    background-color: #fafafa;

    // white-space: initial;
  }
}

.pac-item-selected {
}

.pac-icon {
  flex-shrink: 0;

  display: block;
}

.pac-item-query {
  font-weight: 600;
  color: #000;
}

.pac-matched {
}
