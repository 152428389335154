.rider {
  display: flex;
  align-items: center;
  gap: 1.6rem;

  & img {
    display: block;
    width: 3.6rem;
    height: 3.6rem;
    border-radius: 50%;
    object-fit: cover;
  }
}
