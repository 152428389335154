.shipments-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  margin-bottom: 2.4rem;

  @media (max-width: 650px) {
    flex-direction: column;
    align-items: center;
    gap: 2.4rem;
  }

  &__left {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 1.8rem;
  }

  &__heading {
    font-size: 2.2rem;
    color: #000;
    font-weight: 600;
  }

  &__filter {
    padding: 1.2rem 1.6rem;
    border-radius: 1.2rem;

    display: flex;
    align-items: center;
    gap: 2.4rem;

    background-color: #fff;

    & span {
      cursor: pointer;
    }

    & ion-icon {
      display: block;
      width: 1.6rem;
      height: 1.6rem;
    }
  }

  &__actions {
    display: flex;
    gap: 1.6rem;
  }
}

.shipments-footer {
  margin-top: 2.4rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.shipments-menu {
  position: relative;

  & ion-icon {
    display: block;
    width: 1.8rem;
    height: 1.8rem;
  }

  &__main {
    display: flex;
    align-items: center;
    gap: 8px;

    cursor: pointer;

    & img {
      display: block;
      width: 3.6rem;
      height: 3.6rem;
      border-radius: 50%;
    }
  }

  &__block {
    position: absolute;
    top: 0;
    left: 0;
    width: 25rem;
    max-width: calc(100vh - 4.8rem);
    transform: translateX(-100%);
    z-index: 99999;

    display: none;

    &:hover {
      display: flex;
    }
  }

  &__main:hover + &__block {
    display: flex;
  }

  &__block-top {
    flex: 0 0 2.4rem;
  }

  &__block-main {
    flex: 1;

    background-color: #fff;
    box-shadow: 0 8px 64px rgba($color: #000000, $alpha: 0.1);
    border-radius: 0.6rem;
    overflow: hidden;

    display: flex;
    flex-direction: column;

    & > * {
      display: flex;
      align-items: center;
      gap: 1.2rem;

      padding: 1.6rem 2.4rem;
      color: $text-color !important;

      & ion-icon {
        display: block;
        width: 1.8rem;
        height: 1.8rem;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #f1f1f1;
      }

      &:hover {
        background-color: #eaeaea;
      }
    }
  }
}
